import {io} from 'socket.io-client';

export default class SocketManager {

  socket;
  authenticated = false;

  constructor(token){
    if(SocketManager._instance){
      return SocketManager._instance;
    }
    SocketManager._instance = this;
    this._connect(token);
  }

  _connect(token){
    this.socket = io('https://adssocket.azurewebsites.net', {
      'reconnection': true,
      'transports': ['websocket'],
      'path': '/socket'
    });

    this.socket.on('connect', () => {
      console.log("Connected to socket", this.socket.id);
      this.socket.emit("authentication", { target: "user", token: token });
      this.socket.on('authenticated', () => {
        console.log("Authenticated with Socket!");
        this.authenticated = true;
      });
    });
    
    this.socket.on('reconnect', () => {
      this.socket.emit("authentication", { target: "user", token: token });
    });
  }

}