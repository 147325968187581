<template>
  <h1>Coming Soon!</h1>
</template>

<script>
export default {
  name: "CustomerDashboard",
  mounted(){
    this.$router.push({name: 'customer.jobs'})
  }
}
</script>