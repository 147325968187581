<template>
  <!-- STATS -->
    <div class="row">
      <div class="col-12">
        <div class="card radius-10">
          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 row-group g-0">
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{stats.openJobs.toLocaleString()}}</h5>
                  <div class="ms-auto">
                    <i class="bx bxs-receipt fs-3 text-white"></i>
                  </div>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Open Jobs</p>
                  <!--              <p class="mb-0 ms-auto">+4.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{stats.completedJobs.toLocaleString()}}</h5>
                  <div class="ms-auto">
                    <i class="bx bxs-receipt fs-3 text-white"></i>
                  </div>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Completed Jobs</p>
                  <!--              <p class="mb-0 ms-auto">+1.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{stats.sites.toLocaleString()}}</h5>
                  <div class="ms-auto">
                    <i class="bx bxs-map-pin fs-3 text-white"></i>
                  </div>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Sites</p>
                  <!--              <p class="mb-0 ms-auto">+5.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{stats.devices.toLocaleString()}}</h5>
                  <div class="ms-auto">
                    <i class="bx bxs-devices fs-3 text-white"></i>
                  </div>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Devices</p>
                  <!--              <p class="mb-0 ms-auto">+2.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
          </div><!--end row-->
        </div>
      </div>

    </div>
    <!-- JOB CHARTS -->
    <div class="row">
      <div class="col-12 col-lg-8 col-xl-8 d-flex">
        <div class="card radius-10 w-100">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <h6 class="mb-0">Job Trends</h6>
              </div>
<!--              <div class="font-22 ms-auto text-white"><i class="bx bx-dots-horizontal-rounded"></i>-->
<!--              </div>-->
            </div>
            <div class="d-flex align-items-center ms-auto font-13 gap-2 my-3">
              <span class="border px-1 rounded cursor-pointer"><i class="bx bxs-circle me-1" style="color: lightgreen;"></i>New Jobs</span>
              <span class="border px-1 rounded cursor-pointer"><i class="bx bxs-circle text-white me-1"></i>Completed Jobs</span>
            </div>
            <div class="chart-container-1"><div class="chartjs-size-monitor" style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
              <canvas id="chart1" width="895" height="260" style="display: block; width: 895px; height: 260px;" class="chartjs-render-monitor"></canvas>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-md-3 row-cols-xl-3 g-0 row-group text-center border-top">
            <div class="col">
              <div class="p-3">
                <h5 class="mb-0">{{totalOpenedJobs}}</h5>
                <small class="mb-0">Jobs Created</small>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <h5 class="mb-0">{{totalCompletedJobs}}</h5>
                <small class="mb-0">Jobs Completed</small>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <h5 class="mb-0">{{percentageCompletion}}%</h5>
                <small class="mb-0">% Completion</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 col-xl-4 d-flex">
        <div class="card radius-10 overflow-hidden w-100">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <h6 class="mb-0">Job Types</h6>
              </div>
<!--              <div class="font-22 ms-auto text-white"><i class="bx bx-dots-horizontal-rounded"></i>-->
<!--              </div>-->
            </div>
            <div class="chart-container-2 my-3"><div class="chartjs-size-monitor" style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
              <canvas id="chart2" width="420" height="210" style="display: block; width: 420px; height: 210px;" class="chartjs-render-monitor"></canvas>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <tbody>
              <tr>
                <td><i class="bx bxs-circle text-white me-2"></i> {{jobTrends.types[0]}}</td>
                <td>{{jobTrends.typeCounts[0]}}</td>
              </tr>
              <tr>
                <td><i class="bx bxs-circle text-light-2 me-2"></i>{{jobTrends.types[1]}}</td>
                <td>{{jobTrends.typeCounts[1]}}</td>
              </tr>
              <tr>
                <td><i class="bx bxs-circle text-light-3 me-2"></i>{{jobTrends.types[2]}}</td>
                <td>{{jobTrends.typeCounts[2]}}</td>
              </tr>
              <tr>
                <td><i class="bx bxs-circle text-light-4 me-2"></i>{{jobTrends.types[3]}}</td>
                <td>{{jobTrends.typeCounts[3]}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- RECENTLY COMPLETED JOBS -->
    <div class="row">
      <div class="col-12">
        <div class="card radius-10">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <h5 class="mb-0">Recently Completed Jobs</h5>
              </div>
              <!--            <div class="font-22 ms-auto"><i class="bx bx-dots-horizontal-rounded"></i>-->
              <!--            </div>-->
            </div>
            <hr>
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                <tr>
                  <th>JR #</th>
                  <th>Type</th>
                  <th>Site</th>
                  <th>Postcode</th>
                  <th>Completed By</th>
                  <th>Completed At</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="job in recentJobs" :key="job.id">
                  <td>{{job.id}}</td>
                  <td>{{job.type}}</td>
                  <td>{{job.site_name}}</td>
                  <td>{{job.site_postcode}}</td>
                  <td>{{job.completed_user.fname}} {{job.completed_user.lname}}</td>
                  <td><Time v-if="job.completed_at" :datetime="job.completed_at" format="dd/MM/yyyy HH:mm"></Time></td>
                  <td>
                    <button v-if="$hasRole('Support')" type="button" class="btn btn-sm btn-light" @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})">View Job</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- USER DEVICES -->
    <div class="row">
      <div class="col-12">
        <div class="card radius-10">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div>
                <h5 class="mb-0">User Devices</h5>
              </div>
            </div>
            <hr>
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                <tr>
                  <th>User Name</th>
                  <th>Device Name</th>
                  <th>Battery</th>
                  <th>App Version</th>
                  <th>Last Connected</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="device in userDevices" :key="device.fname">
                  <td>{{device.fname}} {{device.lname}}</td>
                  <td>{{device.device_name}}</td>
                  <td>{{device.battery}}%</td>
                  <td>{{device.version}}</td>
                  <td><Time :datetime="device.last_connected" format="dd/MM/yyyy HH:mm"></Time></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import Time from '../../../components/Time';
import {DateTime} from 'luxon';
import SocketManager from '../../../singletons/SocketManager';
import Swal from 'sweetalert2';
/* global Chart */
/* global $ */
export default {
  name: "Dashboard",
  components: {
    Time
  },
  data(){
    return {
      stats: {
        openJobs: 0,
        completedJobs: 0,
        sites: 0,
        devices: 0
      },
      recentJobs: [],
      userDevices: [],
      showCharts: false,
      jobTrends: {
        open: [],
        completed: [],
        weeks: [],
        types: [],
        typeCounts: []
      },
      newJobs: [],
      completedJobs: [],
      chartData: [],
      chart1: null,
      chart2: null,
      socket: null,
      socketManager: null
    }
  },
  mounted(){
    window.addEventListener('resize', this.resetCharts);
    this.loadStats();
    this.loadRecentJobs();
    this.loadUserDevices();
    this.loadJobChart();
    this.loadJobTypeChart();
    this.socketManager = new SocketManager(this.$store.state.auth.token);
    this.socket = this.socketManager.socket;
    if(this.socketManager.authenticated){
      this.joinChannels();
    }else{
      setTimeout(this.joinChannels, 1500);
    }

    this.socket.on('newsite', this.handleNewSite);
    this.socket.on('newdevice', this.handleNewDevices);
    this.socket.on('newjob', this.handleNewJobs);
    this.socket.on('deletejob', this.handleDeleteJobs);
    this.socket.on('completedjob', this.handleCompletedJobs);
    
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resetCharts);
    this.socket.off('newsite', this.handleNewSite);
    this.socket.off('newdevice', this.handleNewDevices);
    this.socket.off('newjob', this.handleNewJobs);
    this.socket.off('deletejob', this.handleDeleteJobs);
    this.socket.off('completedjob', this.handleCompletedJobs);
  },
  methods: {
    joinChannels(){
      this.socket.emit('join-channel', {channel: 'sites', user_id: this.$store.state.auth.user.id});
      this.socket.emit('join-channel', {channel: 'jobs', user_id: this.$store.state.auth.user.id});
      this.socket.emit('join-channel', {channel: 'devices', user_id: this.$store.state.auth.user.id});
    },
    loadStats(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/dashboard`)
      .then(response => {
        if(response.data.success){
          this.stats = response.data.stats;
        }
      })
      .catch(error => {
        this.$error("Failed to load stats", error);
      });
    },
    loadRecentJobs(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/dashboard/recentjobs`)
      .then(response => {
        if(response.data.success){
          this.recentJobs = response.data.jobs
        }
      })
      .catch(error => {
        this.$error("Failed to load recent jobs", error);
      })
    },
    loadUserDevices(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/userDevices`)
      .then(response => {
        if(response.data.devices){
          this.userDevices = response.data.devices
        }
      })
      .catch(error => {
        this.$error("Failed to load user devices", error);
      })
    },
    resetCharts(){
      try {

        let chart1Container = document.getElementById('chart1').parentNode;
        let chart2Container = document.getElementById('chart2').parentNode;

        $('#chart1').remove();
        $('#chart2').remove();

        let newChart1 = document.createElement('canvas');
        newChart1.setAttribute('id', 'chart1');
        newChart1.setAttribute('style', 'display: block; width: 895px; height: 260px;')
        newChart1.classList.add('chartjs-render-monitor');

        chart1Container.appendChild(newChart1);

        let ctx = document.getElementById('chart1').getContext('2d');

        this.chart1 = new Chart(ctx, {
            type: 'line',
            data: {
              labels: this.jobTrends.weeks,
              datasets: [{
                label: 'New Jobs',
                data: this.newJobs,
                backgroundColor: 'rgba(255, 255, 255, 0.25)',
                borderColor: "lightgreen",
                pointRadius :"0",
                borderWidth: 3
              }, {
                label: 'Completed Jobs',
                data: this.completedJobs,
                backgroundColor: "rgba(255, 255, 255, 0.25)",
                borderColor: "white",
                pointRadius :"0",
                borderWidth: 3
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
                labels: {
                  fontColor: '#ddd',
                  boxWidth:40
                }
              },
              tooltips: {
                displayColors:false
              },
              scales: {
                xAxes: [{
                  ticks: {
                    beginAtZero:true,
                    fontColor: '#ddd'
                  },
                  // gridLines: {
                  //   display: true ,
                  //   color: "rgba(221, 221, 221, 0.08)"
                  // },
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero:true,
                    fontColor: '#ddd'
                  },
                  // gridLines: {
                  //   display: true ,
                  //   color: "rgba(221, 221, 221, 0.08)"
                  // },
                }]
              }

            }
          });

        let newChart2 = document.createElement('canvas');
        newChart2.setAttribute('id', 'chart2');
        newChart2.setAttribute('style', 'display: block; width: 420px; height: 210px;')
        newChart2.classList.add('chartjs-render-monitor');

        chart2Container.appendChild(newChart2);

        ctx = document.getElementById('chart2').getContext('2d');

        this.chart2 = new Chart(ctx, {
            type: 'doughnut',
            data: {
              labels: this.jobTrends.types,
              datasets: [{
                backgroundColor: [
                  "#ffffff",
                  "rgba(255, 255, 255, 0.70)",
                  "rgba(255, 255, 255, 0.50)",
                  "rgba(255, 255, 255, 0.20)"
                ],
                data: this.chartData.map((d) => {return d.count}),
                borderWidth: [0, 0, 0, 0]
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 60,
              legend: {
                position :"bottom",
                display: false,
                labels: {
                  fontColor: '#ddd',
                  boxWidth:15
                }
              }
              ,
              tooltips: {
                displayColors:false
              }
            }
          });

        // <canvas id="chart2" width="420" height="210" style="display: block; width: 420px; height: 210px;" class="chartjs-render-monitor"></canvas>
        // <canvas id="chart1" width="895" height="260" style="display: block; width: 895px; height: 260px;" class="chartjs-render-monitor"></canvas>
        

      }catch(error){
        console.error(error);
        console.log("Failed to reload chart");
      }

    },
    loadJobChart(){

      axios.get(`${process.env.VUE_APP_API_URL}/v1/dashboard/jobtrends`)
      .then(response => {
        if(response.data.success){
          this.jobTrends.open = response.data.open;
          this.jobTrends.completed = response.data.completed;

          let weekNumber = DateTime.local().weekNumber;
          console.log("Week Number:", weekNumber);
          for (let i = 0; i < 12; i ++){
            let wn = weekNumber - i;
            if(wn < 1){
              wn += 52;
            }
            this.jobTrends.weeks.unshift(wn);
          }

          let newJobs = [];

          this.jobTrends.weeks.forEach((week) => {
            let value = 0;
            let existing = this.jobTrends.open.filter((j) => {
              return (j.week + 1) === week;
            });
            if(existing.length > 0){
              value = existing[0].count;
            }
            newJobs.push(value);
          })

          let completedJobs = [];

          this.jobTrends.weeks.forEach((week) => {
            let value = 0;
            let existing = this.jobTrends.completed.filter((j) => {
              return (j.week + 1) === week;
            });
            if(existing.length > 0){
              value = existing[0].count;
            }
            completedJobs.push(value);
          })

          this.newJobs = newJobs;
          this.completedJobs = completedJobs;

          var ctx = document.getElementById('chart1').getContext('2d');

          this.chart1 = new Chart(ctx, {
            type: 'line',
            data: {
              labels: this.jobTrends.weeks,
              datasets: [{
                label: 'New Jobs',
                data: newJobs,
                backgroundColor: 'rgba(255, 255, 255, 0.25)',
                borderColor: "lightgreen",
                pointRadius :"0",
                borderWidth: 3
              }, {
                label: 'Completed Jobs',
                data: completedJobs,
                backgroundColor: "rgba(255, 255, 255, 0.25)",
                borderColor: "white",
                pointRadius :"0",
                borderWidth: 3
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
                labels: {
                  fontColor: '#ddd',
                  boxWidth:40
                }
              },
              tooltips: {
                displayColors:false
              },
              scales: {
                xAxes: [{
                  ticks: {
                    beginAtZero:true,
                    fontColor: '#ddd'
                  },
                  // gridLines: {
                  //   display: true ,
                  //   color: "rgba(221, 221, 221, 0.08)"
                  // },
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero:true,
                    fontColor: '#ddd'
                  },
                  // gridLines: {
                  //   display: true ,
                  //   color: "rgba(221, 221, 221, 0.08)"
                  // },
                }]
              }

            }
          });
        }
      })

    },
    loadJobTypeChart(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/dashboard/jobtypetrend`)
      .then(response => {
        if(response.data.success){

          var ctx = document.getElementById('chart2').getContext('2d');

          let trend = response.data.trend;

          let data = trend.slice(0, 3);
          let other = trend.slice(3);
          let otherCount = 0;
          other.forEach((e) => {
            otherCount += e.count;
          });
          data.push({count: otherCount});
          this.jobTrends.types = [trend[0].type || "No Type", trend[1].type || "No Type", trend[2].type || "No Type", "Other"];
          this.jobTrends.typeCounts = data.map((d) => {return d.count});

          this.chartData = data;

          this.chart2 = new Chart(ctx, {
            type: 'doughnut',
            data: {
              labels: this.jobTrends.types,
              datasets: [{
                backgroundColor: [
                  "#ffffff",
                  "rgba(255, 255, 255, 0.70)",
                  "rgba(255, 255, 255, 0.50)",
                  "rgba(255, 255, 255, 0.20)"
                ],
                data: data.map((d) => {return d.count}),
                borderWidth: [0, 0, 0, 0]
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 60,
              legend: {
                position :"bottom",
                display: false,
                labels: {
                  fontColor: '#ddd',
                  boxWidth:15
                }
              }
              ,
              tooltips: {
                displayColors:false
              }
            }
          });

        }
      })
    },
    handleNewSite(){
      this.stats.sites++;
    },
    handleNewDevices(msg){
      this.stats.devices += msg.count;
    },
    handleNewJobs(){
      this.stats.openJobs++;
    },
    handleCompletedJobs(){
      this.stats.completedJobs++;
      this.stats.openJobs--;
      this.loadRecentJobs();
      let timerInterval
      Swal.fire({
        icon: 'success',
        title: 'Job Completed!',
        html: 'A job has just been completed!',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      });
    },
    handleDeleteJobs(msg){
      if(msg.completed){
        this.completedJobs--;
      }else{
        this.openJobs--;
      }
    }
  },
  computed: {
    totalOpenedJobs: function(){
      let count = 0;
      this.jobTrends.open.forEach((j) => {
        count += j.count;
      });
      return count;
    },
    totalCompletedJobs: function(){
      let count = 0;
      this.jobTrends.completed.forEach((j) => {
        count += j.count;
      });
      return count;
    },
    percentageCompletion: function(){
      return (this.totalCompletedJobs / this.totalOpenedJobs * 100).toFixed(2);
    }
  }
}
</script>

<style scoped>
/* Chart.js */
@-webkit-keyframes chartjs-render-animation{from{opacity:0.99}to{opacity:1}}@keyframes chartjs-render-animation{from{opacity:0.99}to{opacity:1}}.chartjs-render-monitor{-webkit-animation:chartjs-render-animation 0.001s;animation:chartjs-render-animation 0.001s;}
</style>
