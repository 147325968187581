<template>
  <div class="page-content">

    <Staff v-if="!$hasRole('Customer')"></Staff>
    <CustomerDashboard v-else></CustomerDashboard>

  </div>
</template>

<script>
import Staff from './Staff.vue'
import CustomerDashboard from './Customer.vue'
export default {
  name: "Dashboard",
  components: {
    Staff,
    CustomerDashboard
  },
  mounted(){
    console.log(this.$store.state.auth);
  }
}
</script>

